<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="12">
          <CCardGroup>
            <CCard class="p-4">
              <CCardHeader class="font160 text-yellow">
                <strong>กระดานสนทนา</strong>
              </CCardHeader>
              <CCardBody v-if="discussionInfo">
                <div v-if="discussionInfo.length > 0">
                  <div
                    v-for="discussion in discussionInfo"
                    :key="discussion.id"
                  >
                    <div v-if="discussion.std_name">
                      <strong
                        >{{ discussion.std_name }}
                        {{ discussion.std_surname }}</strong
                      >
                    </div>
                    <div v-else-if="discussion.user_fname" class="text-yellow">
                      <strong
                        >{{ discussion.user_fname }}
                        {{ discussion.user_lname }}</strong
                      >
                    </div>
                    <div>{{ discussion.discussion_content }}</div>
                    <div class="text-right">
                      <small>{{ discussion.discussion_created }}</small>
                    </div>
                  </div>
                </div>
                <div v-else>--ไม่มีเนื้อหา--</div>

                <form
                  id="formCreateDiscuss"
                  v-on:submit.prevent="createDiscuss"
                  method="POST"
                  style="margin-top: 20px"
                >
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      name="content"
                      rows="1"
                      placeholder="เขียนความคิดเห็น..."
                      required
                    ></textarea>
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-yellow">ส่ง</button>
                  </div>
                </form>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      discussionInfo: null,
      userId: null,
    };
  },
  mounted() {
    //console.log(this.userData);

    //FETCH COMMENTS
    this.axios
      .get(
        this.$hostUrl +
          "php_action/discussionAPI.php?txtAction=getCommentByTopic&schoolId=" +
          this.$route.query.SchoolID +
          "&topicId=" +
          this.$route.query.SubjectClassID
      )
      .then((response) => {
        console.log(response.data);
        this.discussionInfo = response.data.mainData;
      })
      .finally(() => {});
  },
  methods: {
    createDiscuss(event) {
      //alert(this.$route.query.PersonID);
      this.uploadingStat = 1;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createComment");
      formData.append("schoolId", this.$route.query.SchoolID);
      formData.append("topicId", this.$route.query.SubjectClassID);
      formData.append("userId", this.$route.query.PersonID);
      this.axios
        .post(this.$hostUrl + "php_action/discussionAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "success") {
            alert("บันทึกแล้ว");
            location.reload();
          } else {
            alert(response.data.error);
          }
        })
        .finally(() => {});
    },
  },
};
</script>
